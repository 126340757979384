.content-list {
  margin-bottom: 1rem;

  &__header {
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
    background: @dark-gray;
    z-index: 2;
    position: relative;

    &__cta-icon {
      color: @green;
    }
  }

  &__title {
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    margin: 0;

    &__icon {
      color: var(--main-color);
      margin-right: 1rem;
    }

    &--wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      * > {
        margin: 0 .5rem;
        font-size: 1.5rem;
      }

      .video-list__title__icon {
        margin-right: 1rem;
      }
    }

    &--green {
      color: @green;
    }
  }

  &__subtitle {

  }

  &__items {
    .pub {
      order: 0;
      width: 25%;
    }
  }

  &__empty {
    background: @dark-gray;
    text-align: center;
    color:white;
    padding: 1rem;
  }

  &--facetting {
    padding: 0 1rem;
    width: 100%;
  }

  &--unavoidable {
    background: #1d1d1d;
    margin-bottom: 1rem;

    .video-list {
      &__content {
        padding: 0 1rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .video-list {
    &__header {
      flex-wrap: wrap;
    }

    .dropdown {
      width: 100%;

      &-list__button {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
}

.video-elite {
  display: block;
  position: relative;

  &__cover {
    width: 24.7%;
    position: absolute;
    z-index: 1;
    padding: 15px 7px 0 15px;

    img {
      width: 100%;
      box-shadow: 0px 3px 7px #0000003d;
    }
  }

  &__background {
    width: 100%;
    display: block;
    position: relative;
    padding-top: calc(83 / 244 * 100%);
    height: 0;

    img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    &__cover {
      position: relative !important;
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
      padding-top: calc(743 / 540 * 100%) !important;

      img {
        box-shadow: none;
        margin: 0 auto;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }

    &__background {
      display: none !important;
    }
  }
}
