.userstate {
  [data-is-logged] {
    display: none;
  }

  &--is-authenticated {
    [data-is-logged] {
      display: inherit;
    }

    [data-is-anonymous] {
      display: none;
    }
  }
}

.user_is_authenticated {
  .userstate {
    &__hidden_to_show {
      display: inherit;
    }

    &__hidden_to_show {
      &__force {
        display: inherit !important;
      }
    }

    &__to_hide {
      display: none;
    }
  }
}

.user_has_subscription {
  .has_sub_hide {
    display: none;
  }
}
