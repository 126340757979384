.charte {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 7rem;
  background-color: @dark-gray;

  &__title {
    font-size: 3rem;
    color: var(--main-color);
    margin-top: 0;
    text-align: justify;

    .fas {
      font-size: 2rem;
    }
  }

  &__content {
    color: white;

    strong, a {
      color: var(--main-color);
    }

    .identity {
      margin: 2rem auto;

      img {
        height: 1rem;
        vertical-align: middle;
      }
    }

  }

  h2 {
    color: var(--main-color);
    font-size: 2rem;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 920px){
    padding: 2rem;
  }
}
