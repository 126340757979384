.main-text {
  text-align: center;
  padding: 1rem;
  font-size: 1.3rem;
  color: @mider-gray;

  &__title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--main-color)
  }
}

.bottom-text {
  margin: 5px 0;
  line-height: 15px;
  text-align: justify;
  font-size: 1.3rem;
  color: @mider-gray;

  h3,
  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--main-color)
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1rem;
  }

  li {
    padding: .5rem;
  }

  &--discreet {
    color: @mider-gray;

    h2, p, a {
      display: inline;
      color: @mider-gray;
      font-size: 1.3rem;
      line-height: 1.2;
      margin: 0;
    }

    a {
      font-weight: normal;
    }
  }
}


.tag-intro {
  overflow: hidden;
  position: relative;
  max-width: 100%;
  padding: 2rem;
  font-size: 1.2rem;
  height: auto;
  width: 100%;
  z-index: 1;

  &__text {
    line-height: 20px;
    text-align: justify;
    max-height: 15rem;
    color: @lighter-gray;
    overflow: hidden;
    position: relative;

    h2 {
      font-weight: bold;
      font-size: clamp(1rem, 2vw, 1.5rem);
    }

    a {
      color: var(--main-color);
    }

    b, strong {
      font-weight: bold;
    }

    ul {
      margin-bottom: 10px;
      padding-left: 20px;
    }

    &:after {
      z-index: 1;
      display: block;
      position: absolute;
      content: '';
      width: 100%;
      height: 8rem;
      max-height: 100%;
      pointer-events: none;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 1) 100%);
      bottom: 0;
      left: 0;
    }
  }

  &__show-more {
    z-index: 1;
    display: flex;

    span {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      cursor: pointer;
      text-align: center;
      color: @white;
      line-height: 20px;
      font-size: 13px;
      text-transform: capitalize;
      font-weight: bold;
    }

    .show_text-less {
      display: none;
    }

    .show_text-more {
      display: block;
    }
  }

  &.active {
    .tag-intro {
      &__text {
        max-height: 100%;

        &:after {
          opacity: 0;
        }
      }

      &__show-more {
        .show_text-less {
          display: block;
        }

        .show_text-more {
          display: none;
        }
      }
    }
  }
}

.post {
  margin-bottom: 3rem;

  &__title {
    font-size: 2rem;
    color: @lighter-gray;
    margin: 1rem 0;
    color: var(--main-color)
  }

  &__description {
    color: @lighter-gray;
    font-size: 1.5rem;
    padding: 2rem;
    border: 1px solid @light-gray;

    h2 {
      font-size: 2rem;
      color: var(--main-color);
      margin: 0 0 1rem 0;
    }

    a {
      color: var(--main-color)
    }
  }
}
