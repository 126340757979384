.widget-come-back {
  display: block;
  z-index: 49;
  position: fixed;
  max-width: 550px;
  height: auto;
  bottom: 50px;
  right: 50px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.51)) drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.51));
  border-radius: 10px;
  background-color: #181618;
  background-image: url("../img/bg-widget-user-come-back.svg");
  background-size: cover;

  &.hide {
    display:none;
  }

  .widget-logo {
    display: block;
    position: absolute;
    top: -15px;
    height: auto;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    width: 100%;
    max-width: 240px;
    .drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.70));

    img {
      width: 100%;
    }
  }

  .widget-bell {
    position: absolute;
    font-size: 2rem;
    color: var(--main-color);
    background-color: #222222;
    padding: 10px 16px;
    max-width: 42px;
    max-height: 42px;
    bottom: -21px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;

    .bell {
      -webkit-animation: ring 4s .7s ease-in-out infinite;
      -webkit-transform-origin: 50% 4px;
      -moz-animation: ring 4s .7s ease-in-out infinite;
      -moz-transform-origin: 50% 4px;
      animation: ring 4s .7s ease-in-out infinite;
      transform-origin: 50% 4px;
      position: relative;
      left: -5px;
      bottom: 5px;
    }
  }

  .widget-close {
    background: var(--main-color);
    background: -moz-linear-gradient(0deg, #2c58b8 0%, #3c7bff 100%);
    background: -webkit-linear-gradient(0deg, #2c58b8 0%, #3c7bff 100%);
    background: linear-gradient(0deg, #2c58b8 0%, #3c7bff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F03852", endColorstr="#AA293A", GradientType=1);
    border-radius: 5px;
    width: 100%;
    font-size: 1.8rem;
    color: white !important;
    padding: 15px;
    margin: 17px auto 15px auto;
    font-weight: bold;
    text-transform: uppercase;
    .transition(all 0.3s cubic-bezier(.42, 0, .18, 1));
    transform: scale(1);
    position: relative;
    display: block;
    text-align: center;
    text-decoration: none;

    &:hover {
      .scale(1.05);
    }

    &:focus {
      .transition(all 0.1s cubic-bezier(.42, 0, .18, 1));
      .scale(.8);
    }
  }

  .nothanks {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(64, 64, 64, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(64, 64, 64, 1) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(64, 64, 64, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#404040", GradientType=1);
    font-size: 1.4rem;
    display: flex;
    width: max-content;
    padding: 8px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .widget-content {
    padding: 40px 60px 35px 60px;

    .widget-description {
      p {
        margin: 0;
        text-align: center;

        span {
          font-weight: bold;
        }
      }

      .widget-text-event {
        color: var(--main-color);
        font-size: 2.1rem;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .widget-text-extra {
        color: @white;
        font-size: 1.4rem;
        font-weight: normal;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .widget-timer,
      .widget-promo {
        border: 1px solid var(--main-color);
        .border-radius(5px);
        position: relative;
        background: rgb(22, 22, 22);
        background: linear-gradient(90deg, rgba(22, 22, 22, 1) 0%, rgba(60, 123, 255, 0.22) 50%, rgba(22, 22, 22, 1) 100%);
        display: block;
        max-width: fit-content;
        margin: 0 auto;
        padding: 5px 15px;
        margin-top: 25px;
        padding-top: 13px;


        .widget-text-timer, .widget-text-promo {
          color: white;
          font-size: 3rem;
          font-weight: bold;
        }
      }
    }
  }
}

.widget-come-back {
  .widget-content {
    .widget-description {
      .widget-promo {
        padding: 5px 15px;
        margin: 0;
        display: flex;
        max-width: 100%;
        place-content: center;

        .widget-text-promo {
          font-size: 6rem;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .widget-come-back {
    .animation-name(slideInUp);
    .drop-shadow(0px 0px 80px rgba(0, 0, 0, 1), 0px 0px 20px rgba(0, 0, 0, 1));
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100%;


    .widget-content {
      padding: 15px 30px;
      padding-top: 40px;

      .widget-description {
        .widget-text-event {
          font-size: 2rem;
        }

        .widget-text-extra {
          font-size: 1.6rem;
        }
      }
    }

    .widget-bell {
      bottom: inherit;
      left: 7px;
      top: 7px;
      right: initial;
      background-color: transparent;
    }
  }
}

// FOR IE

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .widget-come-back {
    background-image: none;
    box-shadow: 0px 0px 15px #181818;
    background-color: rgb(27, 27, 27);

    .widget-bell {
      display: none;
    }

    .widget-content {
      .widget-description {
        .widget-promo {
          display: block;
        }
      }
    }

    .nothanks {
      width: 100%;
      display: block;
    }
  }
}
