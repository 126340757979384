/* DISCLAIMER ----------------------------------------------- */

.disclaimer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 999;
  line-height: 25px;
  background-color: black;
  overflow: auto;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/background-disclaimer-illicoporno.jpg");
    background-color: black;
    background-size: cover;
    background-position: top center;
    image-rendering: optimizequality;
  }

  &__container {
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: saturate(100%) blur(40px);
    -webkit-backdrop-filter: saturate(100%) blur(40px);
    position: absolute;
    top: 70%;
    color: white;
    width: 100%;

    &:before {
      content: '';
      height: 200%;
      width: 100%;
      position: absolute;
      top: -0%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  &__logo {
    text-align: center;
    margin-top: 10px;
    max-height: 65px;
    max-width: 400px;
    align-self: center;
  }

  &__logo18 {
    left: 0;
    right: 0;
    top: -20px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    display: flex;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 50%;
    place-content: center;
    max-width: 40px;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;
    color: var(--main-color);
    font-weight: bold;

    img {
      max-width: 50px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    line-height: 25px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &__infos {
    text-align: justify;
    font-size: 12px;
    line-height: 18px;
  }

  &__ctas {
    width: 100%;
    text-align: center;
    align-self: center;
    max-width: 380px;
  }

  &__cta {
    color: white;
    background-color: var(--main-color);
    display: block;
    position: relative;
    padding: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
    transition: ease-in-out 0.3s;
    text-decoration: none;

    &--transparent {
      background-color: transparent;
      font-size: 16px;
    }
  }

  &__description {
    text-align: justify;
    color: white;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 10px;

    &:first-child {
      border-top: 0;
    }

    &__title {
      margin: 0;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }

    &__text {
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
      text-align: justify;
      margin-top: 10px;
      margin-bottom: 10px;

      a {
        color: white;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .disclaimer {
    &__container {
      top: 45vh;
    }

    &__background {
      height: 55vh;
    }
  }
}
