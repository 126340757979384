.tags-section {
  background: @gray;
  padding-bottom: 4rem;

  .carousel-content {
    width: 100%;
  }
}

.tags {
  &__title {
    color: white;
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
  }

  &__description {
    font-weight: normal;
    color: #9c9c9c;
    font-size: 1.5rem;
    text-align: center;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__dropdown {
      margin: 0 .5rem;
      position: relative;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: .5rem;

        .dropdown__content {
          width: 100%;
          margin: 0;
        }
      }
    }

    &__toggle {
      border-radius: .5rem;
      background-color: transparent;
      border: 2px solid var(--main-color);
      color: var(--main-color);
      padding: 1rem;
      width: 100%;
    }

    &__content {
      border-radius: .5rem;
      background-color: black;
      min-width: 100%;
      padding: 10px;
      border: solid 2px var(--main-color);
      cursor: pointer;
      text-align: left;
    }

    &__item {
      width: 100%;
      background-color: transparent;
      color: white;
      border: none;
      white-space: nowrap;
      padding: .5rem;
      text-align: left;

      i {
        color: var(--main-color);
        margin-right: 1rem;
      }
    }

    &__input {
      margin: 0 .5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: .5rem;
      border: 2px solid var(--main-color);

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      span {
        color: var(--main-color);
        margin: 0 1rem;
      }

      input {
        padding: 1rem;
        color: white;
        background: none;
        border: none;
        border-left: 2px solid var(--main-color);

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.31);
        }
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
    padding: 0;
    margin-top: 30px;
    list-style: none;

    &-item {
      color: white;
      text-transform: capitalize;
      text-decoration: none;

      &__counter {
        color: var(--main-color);
        margin-left: .5rem;

        i {
          margin-right: .5rem;
        }
      }
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 920px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}


// CAROUSEL

.carousel-content {
  position: relative;

  &__button {
    transition-duration: 0.2s;
    background-color: var(--main-color);
    border-radius: 50%;
    font-size: 2rem;
    color: white;
    padding: 20px;
    box-shadow: 0px 0px 20px black;
    top: 50%;
    .translateY(-50%);
    position: absolute;
    cursor: pointer;

    &--next {
      left: 10%;
    }

    &--prev {
      right: 10%;
    }
  }
}


//********************** EFFECTS AND ANIMATIONS

.grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: transform;
  transition-property: transform;

  &:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

/* CAROUSEL */

.carousel-container {
  margin-left: -15px;
  margin-right: -15px;
}

.carousel-content {
  padding: 2rem 0;
  overflow: hidden;
}

.tags-carousel {
  &__wrapper {
    box-shadow: 0px 0px 30px #000;
  }

  &__item {
    text-decoration: none;
    font-style: normal;
    display: block;
    position: relative;
    padding-top: calc(3 / 4 * 100%);
    width: 100%;
    height: 0;
    overflow: hidden;
    background: black;
  }

  &__img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__text {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 30%;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
    color: #fff;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin: 0 auto;
    position: relative;
  }

  &__subtitle {
    color: var(--main-color);
    font-size: 1.8rem;
    font-weight: normal;
    margin: 0;
    position: relative;
  }
}

.video-tags {
  background: @dark-gray;
  color: @lighter-gray;
  padding: 2rem;

  &__link {
    color: var(--main-color);
    white-space: nowrap
  }
}
