.public-form {
  background-color: @dark-gray;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 5rem;
  align-items: center;

  &__left {
    padding: 0 2rem;
  }

  &__right {
    border-left: 1px solid @mider-gray;
    padding: 0 2rem;
  }

  &--small {
    width: 100%;
    max-width: 75rem;
    margin: 2rem auto;
  }

  &__header {
    .public-form {
      &__title {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    color: var(--main-color);
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-weight: normal;
    text-align: center;
  }

  &__subtitle {
    color: @lighter-gray;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-weight: normal;
    text-align: center;
    display: block;

    &--link {
      text-decoration: underline;
    }
  }

  &__link {
    color: var(--main-color);
  }

  &__form {
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
    display: grid;
    grid-row-gap: 2rem;

    &__subtitle {
      color: @lighter-gray;
      margin: 1rem 0;
      text-align: center;
      font-size: 1.2rem;
    }

    .form-control {
      padding: 1rem;
      border-radius: .5rem;
      font-family: 'OpenSans', sans-serif;
      font-size: 1.5rem;
      border: none;
      width: 100%;
    }

    .control-label {
      color: @lighter-gray;
      margin-bottom: .5rem;
      display: block;
    }

    .cta {
      width: 100%;
      text-transform: uppercase;
    }

    label[for=id_captcha] {
      display: none;
    }

    .g-recaptcha > div {
      margin: auto;
    }

  }

  &__advantages {
    padding: 0 2rem;

    &__item {
      display: flex;
      align-items: center;
      color: gray;
      padding: 1rem;
      font-size: 1.8rem;

      i {
        margin-right: 1rem;
        color: @green;
      }

      p {
        margin: 0;
      }
    }
  }

  &__footer {
    text-align: center;
    font-size: 1.2rem;
    color: @lighter-gray;
    display: block;
    margin: 2rem 0;

    &__link {
      color: var(--main-color);
      display: inline-block;
    }
  }

  &__thumb-video {
    position: relative;
    max-width: 35rem;
    margin: 0 auto;
    border-radius: 1rem;
    overflow: hidden;

    &__img {
      display: block;
      width: 100%;
    }

    &__timer {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      border-radius: .5rem;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      padding: .5rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
}
