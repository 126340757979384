.video-item {
  display: block;
  position: relative;
  margin-bottom: var(--gap-between-col);

  &--locked {
    cursor: pointer;

    .video-item {
      &__thumb {
        border: 1px solid #727272;

        &__img {
          filter: blur(10px);
          opacity: 0.4;
        }
      }
    }
  }

  &__thumb {
    border: 1px solid @light-gray;
    display: block;
    width: 100%;
    padding-bottom: calc(3 / 4 * 100%);
    overflow: hidden;
    height: 0;
    position: relative;

    &__img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &__cta {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      z-index: 1;
      color: white;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 700;

      img {
        width: 75%;
      }
    }

    &--cover {
      padding-bottom: calc(4 / 3 * 100%);
    }
  }

  &__timer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;

    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.80) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.80) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.80) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0); /* IE6-9 */
    color: white;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1rem;
  }

  &__title {
    font-size: 1.4rem;
    color: var(--main-color);
    text-decoration: none;
    margin-top: 1rem;
    display: block;
  }

  &__infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    color: @lighter-gray;
    flex-wrap: wrap;
  }

  &__info {
    margin: 0;

    &:first-child {
      padding-right: .5rem;
    }
  }

  &__delete {
    position: absolute;
    z-index: 10;
    right: 2rem;
    top: 1rem;
    color: white !important;
    background: @red;
    padding: 0.6rem 0.8rem .5rem .7rem;
    border-radius: 50%;
    font-size: 1.2rem;
    width: auto !important;

    i {
      display: block;
    }

    &:hover, &:focus, &:active {
      background-color: @dark-red;
    }
  }

  iframe {
    width: 100% !important;
    height: 100%;
  }
}
