// mixins
@import "mixins/_colors";
@import "mixins/_animations";

// Vendors
@import "../app/lesshat/build/lesshat";
@import "../fonts/fontawesome/less/_variables";
@import (css) "../../webpack/node_modules/tiny-slider/dist/tiny-slider.css";

// Base

@import "base/_reset";
@import "base/_typography";
@import "base/_init";


// Utils

@import "utils/_grid-layout";
@import "utils/_cta";
@import "utils/_pub";
@import "utils/_seo";
@import "utils/_alert";
@import "utils/_userstate";
@import "utils/_plq";


// Components
@import "components/_disclaimer";
@import "components/_widget-comeback";
@import "components/_widget-head";
@import "components/_widget-offer";

@import "components/_content-list";
@import "components/_video-item";
@import "components/_tags";
@import "components/_pagination";
@import "components/_advanced-search";

@import "components/_public-form";
@import "components/_main";
@import "components/_header";
@import "components/_footer";
@import "components/_dropdown";
@import "components/_dalenys-modal";

@import "pages/_video-detail";
@import "pages/_settings";
@import "pages/_errors";
@import "pages/_charte";
