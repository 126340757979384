@import (less) "../../fonts/fontawesome/less/brands.less";
@import (less) "../../fonts/fontawesome/less/duotone.less";
@import (less) "../../fonts/fontawesome/less/fontawesome.less";
@import (less) "../../fonts/fontawesome/less/light.less";
@import (less) "../../fonts/fontawesome/less/regular.less";
@import (less) "../../fonts/fontawesome/less/solid.less";
@import (less) "../../fonts/fontawesome/less/v4-shims.less";

/* REGULAR */

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/Open_Sans/regular/opensans-regular-webfont.eot');
  src: url('../../fonts/Open_Sans/regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Open_Sans/regular/opensans-regular-webfont.woff2') format('woff2'),
  url('../../fonts/Open_Sans/regular/opensans-regular-webfont.woff') format('woff'),
  url('../../fonts/Open_Sans/regular/opensans-regular-webfont.ttf') format('truetype'),
  url('../../fonts/Open_Sans/regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/Open_Sans/regular/opensans-italic-webfont.eot');
  src: url('../../fonts/Open_Sans/regular/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Open_Sans/regular/opensans-italic-webfont.woff2') format('woff2'),
  url('../../fonts/Open_Sans/regular/opensans-italic-webfont.woff') format('woff'),
  url('../../fonts/Open_Sans/regular/opensans-italic-webfont.ttf') format('truetype'),
  url('../../fonts/Open_Sans/regular/opensans-italic-webfont.svg#open_sansitalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* SEMI BOLD */

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/Open_Sans/semibold/opensans-semibold-webfont.eot');
  src: url('../../fonts/Open_Sans/semibold/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Open_Sans/semibold/opensans-semibold-webfont.woff2') format('woff2'),
  url('../../fonts/Open_Sans/semibold/opensans-semibold-webfont.woff') format('woff'),
  url('../../fonts/Open_Sans/semibold/opensans-semibold-webfont.ttf') format('truetype'),
  url('../../fonts/Open_Sans/semibold/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/Open_Sans/semibold/opensans-semibolditalic-webfont.eot');
  src: url('../../fonts/Open_Sans/semibold/opensans-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Open_Sans/semibold/opensans-semibolditalic-webfont.woff2') format('woff2'),
  url('../../fonts/Open_Sans/semibold/opensans-semibolditalic-webfont.woff') format('woff'),
  url('../../fonts/Open_Sans/semibold/opensans-semibolditalic-webfont.ttf') format('truetype'),
  url('../../fonts/Open_Sans/semibold/opensans-semibolditalic-webfont.svg#open_sanssemibold_italic') format('svg');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

/* BOLD */

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/Open_Sans/bold/opensans-bold-webfont.eot');
  src: url('../../fonts/Open_Sans/bold/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Open_Sans/bold/opensans-bold-webfont.woff2') format('woff2'),
  url('../../fonts/Open_Sans/bold/opensans-bold-webfont.woff') format('woff'),
  url('../../fonts/Open_Sans/bold/opensans-bold-webfont.ttf') format('truetype'),
  url('../../fonts/Open_Sans/bold/opensans-bold-webfont.svg#open_sansbold') format('svg');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/Open_Sans/bold/opensans-bolditalic-webfont.eot');
  src: url('../../fonts/Open_Sans/bold/opensans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Open_Sans/bold/opensans-bolditalic-webfont.woff2') format('woff2'),
  url('../../fonts/Open_Sans/bold/opensans-bolditalic-webfont.woff') format('woff'),
  url('../../fonts/Open_Sans/bold/opensans-bolditalic-webfont.ttf') format('truetype'),
  url('../../fonts/Open_Sans/bold/opensans-bolditalic-webfont.svg#open_sansbold_italic') format('svg');
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}
