.settings-navbar {
  padding: 2rem 0;
  list-style: none;
  background-color: @light-gray;

  &__item {
    padding: 1rem 2rem;
    .transition(all ease 500ms);

    &:hover, &:focus, &active {
      background-color: @gray;
    }

  }

  &__link {
    font-size: 1.5rem;
    color: @lighter-gray;
    .transition(all ease 500ms);
    display: block;
    text-decoration: none;
    position: relative;

    .fas {
      margin-right: 1rem;
      color: var(--main-color);
    }

    span {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 1rem;
      opacity: 0.5;
    }
  }
}

.private-form {
  padding: 0 2rem;

  &__title {
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    margin: 0;

    &__icon {
      color: var(--main-color);
      margin-right: 1rem;
    }
  }

  &__success {
    color: @fluo-green;
    width: 100%;
    text-align: center;
    border: 1px solid @fluo-green;
    padding: 2rem;
    font-weight: bold;
  }

  label {
    display: block;
    color: @lighter-gray;
    margin-bottom: 2rem;
    border-bottom: 0.1rem solid @gray;
    padding-bottom: 1rem;
    width: 100%;
    font-weight: normal;
    font-size: 1.5rem;
  }

  p {
    color: @lighter-gray;
    font-size: 1.3rem;
  }

  select,
  input {
    padding: 1rem;
    border-radius: .5rem;
    font-family: 'OpenSans', sans-serif;
    font-size: 1.5rem;
    border: none;
    width: 100%;
    max-width: 45rem;
    background: white;
  }

  .form-group {
    margin: 2rem 0;
    position: relative;
  }

  select.form-control {
    max-width: 45rem;
  }
}

.help-block {
  display: block;
  font-size: 1.5rem;
  color: @red;
  margin-top: .6rem;
}
