.footer {
    background: #1d1d1d;
}

.footer__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    color: white;
    justify-content: center;

    &__second {
        font-size: smaller;
        padding-top: 0;
    }

}

.footer__copyright {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    padding-top: 0;
    color: white;
    justify-content: center;
    align-items: center;
}

.footer__link {
    color: var(--main-color);
    padding: 0 1rem;
}

.footer-title {
    color: white;
    font-weight: bold;
}

.footer-text {
    color: rgb(63, 63, 63);
    font-weight: 400;
    font-size: 12px;
}