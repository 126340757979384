.widget-head {
  display: none;
  z-index: 2;
  padding: 10px;
  background-color: @red;
  position: relative;
  top: 0;
  width: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    width: 100% ;
  }

  &__text {
    margin: 0;
    padding: 0;
    text-align: center;
    color: @white;
    font-size: 1.4rem;
  }

  &__cta {
    padding: 5px;
    margin: 3px 10px;
    background-color: #00000059;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    .border-radius(5px);
    border: none;
    cursor: pointer;
    .transition(all cubic-bezier(0.15, 0, 0, 1) 250ms);
    outline: none;
    display: inline-block;

    &:hover {
      .scale(1.1);
    }
  }

  &__close {
    color: white;
    font-size: 1.5rem;
  }

  &.show {
    display: flex !important;
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;
  }

  &.hide {
    display: none;
    .transition(transform ease 0.3s);
  }
}
