.espace-cam {
  padding: 2rem 0;
  text-align: center;
  color: @lighter-gray;

  &__content {
    width: 100%;
  }

  a {
    display: block;
  }

  img {
    width: 100%;
    display: block;
  }

  &__title {
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    width: 100%;
    font-weight: bold;
    margin: 1rem auto;

    i {
      color: var(--main-color);
      font-size: 1.6rem;
    }
  }
}

.espace-cam {
  #carasex-pub-3,
  #livejasmin {
    color: @white;
    margin: 0 auto 1rem auto;
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
  }

  #pub-wrpdpule {
    height: 135px;
  }

  #pub-carasexe-3 {
    height: 90px;
  }

  @media screen and (max-width: 1200px) {
    #pub-wrpdpule {
      height: 110px;
    }
  }
}

@media screen and (max-width: 768px) {
  .espace-cam {
    padding: 1rem;
  }
}

.get-premium {
  padding-left: 1rem;
  padding-top: calc(51 / 335 * 100%);
  height: 0;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    padding-left: 1.5rem;
    padding-top: calc(289 / 708 * 100%);

    img {
      width: 100%;
      display: block;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  a {
    display: block;
  }

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.daily-premium {
  margin-bottom: 1rem;
  padding-top: calc(51 / 335 * 100%);
  height: 0;
  position: relative;

  @media screen and (max-width: 768px) {
    padding-bottom: 1rem;
    margin-top: 0;
    padding-top: calc(289 / 708 * 100%);

    img {
      width: 100%;
      display: block;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  a {
    display: block;
  }

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}


.lkj {
  margin-top: 20px;

  img {
    width: 100%;
  }
}


.video-list .video-item iframe {
  height: 250px;
}

@media screen and (min-width: 576px) {
  .video-list .video-item iframe {
    height: 345px;
  }
}

@media screen and (min-width: 768px) {
  .video-list .video-item iframe {
    height: 290px;
  }
}

@media screen and (min-width: 1200px) {
  .video-list .video-item iframe {
    height: 300px;
  }
}

@media screen and (min-width: 1400px) {
  .video-list .video-item iframe {
    height: 330px;
  }
}
