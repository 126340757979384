.main {
  flex: 1;
  width: 100%;

  &__header {
    background: @dark-gray;
  }

  &__section {
    padding: 3rem 0;
    &--gray {
      background: @dark-gray;
    }
  }
}
