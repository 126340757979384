.dropdown {
  position: relative;

  &__toggle {
    cursor: pointer;

    &--carret {
      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .5rem;
        vertical-align: middle;
        border-top: .5rem dashed;
        border-right: .5rem solid transparent;
        border-left: .5rem solid transparent;
      }
    }
  }

  &__content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3;
    min-width: 160px;
    padding: 5px 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    overflow: hidden;
    margin-top: 1rem;
    display: none;

    &--right {
      left: auto;
      right: 0;
    }

    &--top {
      bottom: 100%;
      top: auto;
      right: 0;
      margin-bottom: .5rem;
      margin-top: 0;
    }

    @media screen and (max-width: 768px) {
      width: 100%;

      &--mobile-left {
        left: 0;
        right: 0;
      }
    }
  }

  &--open {
    .dropdown {
      &__content {
        display: flex;
      }

      &__toggle {
        &--carret {
          &:after {
            border-top: 0;
            border-bottom: .5rem dashed;
            border-right: .5rem solid transparent;
            border-left: .5rem solid transparent;
          }
        }
      }
    }
  }
}

.dropdown-download {
  &__content {
    width: 100%;
    max-height: 25rem;
    overflow: auto;
    background: @green;
    border: none;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    margin-top: 1rem;
  }

  &__link {
    padding: 1rem;
    color: @white;
    display: block;
    position: relative;

    &:before {
      content: @fa-var-download;
      font-family: 'font awesome 5 pro';
      font-weight: 400;
      margin-right: 1rem;
    }

    &:hover {
      background: @dark-green;
    }
  }
}

.dropdown-tags {
  position: unset;

  .dropdown__content {
    margin-top: 0;
    width: 100%;
    padding: 0;
  }

  &__content {
    width: 100%;
    background: #1d1d1d;
    padding: 3rem;
    flex-direction: column;
  }

  &__form {
    width: 100%;
    position: relative;
    color: #747474;
    margin-bottom: 2rem;
  }

  &__input {
    height: 100%;
    display: block;
    background: transparent;
    font-size: 1.5rem;
    font-family: 'OpenSans', sans-serif;
    border: 1px solid #292929;
    width: 100%;
    outline: none;
    padding: 2rem 2rem 2rem 5rem;
    color: white;
    border-radius: .5rem;
  }

  &__submit {
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--main-color);
    border: none;
    background: 0 0;
    padding: 0;
    margin: 0;

    &:before {
      content: "\f002";
      font-family: 'Font Awesome 5 Pro', sans-serif;
    }
  }

  &__header {
    font-size: 1.5rem;
    color: var(--main-color);
    margin-top: 0;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;

    &:after {
      content: '';
      background: #313030;
      height: 1px;
      width: 4rem;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__link {
    display: block;
    font-size: 1.4rem;
    color: #fff;
    text-transform: none;
    text-decoration: none;
    padding: .75rem 1rem;
    .transition(padding-left ease 250ms);

    &--bold {
      font-weight: bold;
    }

    &:hover {
      padding-left: 1.5rem;
    }
  }

  &__item {
    display: flex;
  }
}


.dropdown-user {
  .dropdown__content {
    margin-top: 0;
    padding: 0;
  }

  &__content {
    background: @dark-gray;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
    width: 100%;
    padding: 1rem 0;
  }

  &__item {
    display: flex;
    width: 100%;
  }

  &__link {
    color: #fff;
    padding: 0.5rem 2rem;
    display: block;
    width: 100%;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      background-color: @gray;
    }

    &__icon {
      margin-right: 1rem;
      color: var(--main-color);
    }
  }
}

@media screen and (max-width: 920px) {
  .dropdown-tags {
    display: contents;

    .dropdown__toggle {
      display:none;
    }

    &__content {
      padding: 1rem;
      background: black;
      border-radius: 1rem;
      margin-bottom: 1rem;
    }

    &__list {
      grid-template-columns: 1fr;
      max-height: 25rem;
      overflow: auto;
    }

    .dropdown__content {
      display: block;
      position: relative;
      top: unset;
      width: 100%;
    }
  }
}
