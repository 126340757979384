.navigation {
  &__search {
    align-items: center;
    display: flex;
    padding: 0 2rem;
  }

  &__logo {
    display: block;
    padding: 2rem 0;
    width: 25rem;
    max-width: 60vw;

    img {
      width: 100%;
      display: block;
    }
  }

  &__content {
    padding: 0 2rem;
    width: var(--container-width);
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
  }

  &__links {
    display: flex;
  }

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    border-right: 1px solid #383838;

    &:last-child {
      border-right: none;
    }

    &--plq {
      position: relative;

      &:before {
        content: attr(data-plans-q-notifications);
        position: absolute;
        z-index: 1;
        border-radius: 4rem;
        display: flex;
        right: 1rem;
        top: 1rem;
        align-items: center;
        justify-content: right;
        text-align: right;
        padding: 0 .70rem;
        color: white;
        background: @green;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 2rem;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.50);

        @media screen and (max-width: 768px) {
          right: 2rem;
        }
      }

      &:hover {
        background-color: inherit;
      }
    }
  }

  &__cta {
    text-align: center;
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;

    &--gray {
      background: @gray;
      color: white;
    }

    &--main {
      background: var(--main-color);
      color: white;
    }
  }

  &__link {
    white-space: nowrap;
    padding: 1.5rem;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    border: none;
    font-weight: normal;
    font-size: clamp(1.2rem, 1vw, 1.5rem);
    text-transform: uppercase;
    .transition(all ease 250ms);

    &:hover {
      background: @gray;
    }

    &--wide {
      padding: 1rem 3rem;
    }

    &--gray {
      background: @gray;
    }

    &--main-color {
      color: var(--main-color);
    }

    &--main-bg {
      background: var(--main-color);
      color: white;

      &:hover {
        background: var(--dark-main-color);
      }
    }

    &__icons {
      margin-right: 1rem;

      &--white {
        color: #000;
        margin-right: 0.5rem;
        background: #fff;
        padding: 0.4rem;
        border-radius: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1;
        text-align: center;
      }
    }
  }

  &__right {
    flex: 1;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    display: flex;
  }

  &__toggle {
    display: none;
    grid-template-rows: repeat(3, 4px);
    grid-template-columns: 30px;
    grid-gap: 6px;
    padding: 1rem;
    align-items: center;
    place-content: center;

    &__bar {
      width: 100%;
      background: white;
      height: 100%;
      display: block;
      border-radius: 1px;

      &--short {
        width: 50%;
        justify-self: flex-end;
      }
    }
  }

  &__toggle {

  }

  &__desktop {
    display: contents;
  }

  &__mobile {
    display: contents;
  }
}

.search {
  display: flex;
  overflow: hidden;
  border-radius: 1rem;

  &__input {
    padding: 1rem;
    border: none;
    width: 20rem;
    max-width: 20vw;
  }

  &__submit {
    color: white;
    background: var(--main-color);
    padding: 1rem 1.5rem;
  }
}


@media screen and (max-width: 920px) {
  .navigation {
    &__toggle {
      display: grid;
    }

    &__mobile {
      display: none;
      position: fixed;
      width: 100%;
      left: 0;
      height: 100%;
      z-index: 50;
      background: rgba(0, 0, 0, .8);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);

      &--show {
        display: block;
      }
    }

    &__close {
      position: absolute;
      width: 3rem;
      height: 3rem;
      right: 1.5rem;
      top: 1.5rem;
      padding: 0;
      background: transparent;
      display: block;

      &__bar {
        background: white;
        height: .5rem;
        width: 100%;
        display: block;
        border-radius: 1rem;
        position: absolute;
        top: 50%;
        left: 50%;

        &:first-child {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &__left {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__right {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1rem 2rem ;
      background: @light-gray;
      width: calc(100% - 6rem);
      height: 100%;
    }

    &__item {
      width: 100%;

      &--log {
        width: 100%;
        padding: 1rem 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        order: -2;
        border-bottom: 1px solid #484747;
      }
    }

    &__links {
      display: contents;
    }

    &__link {
      height: auto;
      text-align: left;
      justify-content: left;
      padding: 1rem 0;
      font-size: 1.5rem;
    }

    &__item {
      flex: none;
      border-right: 0;
    }

    &__search {
      width: 100%;
      padding: 1rem 0;
      order: -1;
    }
  }

  .search {
    width: 100%;

    &__input {
      width: 100%;
      max-width: 100%;
    }
  }
}
