.video-player {
  position: relative;

  &__content {
    padding-top: calc(9 / 16 * 100%);
    width: 100%;
    height: 0;
    position: relative;
  }

  &__player {
    position: absolute !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__poster {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 2;
  }

  &__play {
    cursor: pointer;
    z-index: 3;
    position: absolute;
    background: 0 0;
    left: 50%;
    top: 50%;
    border: 3px solid white;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    .translate(-50%, -50%);
    outline: none;
    background: rgba(0, 0, 0, 0.5);

    &:before {
      content: '\f04b';
      font-family: 'Font Awesome 5 Pro';

      color: #ffffff;
      font-size: 3rem;
      display: block;
      font-weight: 900;
    }
  }
}

.my18pass-blur {
  .video-player {
    &__content {
      &.nsfw {
        padding-top: 56.25%;
        overflow: hidden;
        filter: blur(15px);

        .video-player {
          &__player {
            display: none;
          }
        }
      }
    }
  }
}

.video-detail {
  background: @dark-gray;

  &__no-video {
    background: url('../../css/img/cloudflare-background.jpg') no-repeat center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 8rem 2rem;

    &__title {
      color: white;
      font-size: 2rem;
    }

    &__subtitle {
      color: var(--main-color);
    }
  }

  &__header {
    padding: 2rem;
  }

  &__title {
    color: var(--main-color);
    font-weight: bold;
    font-size: 1.8rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__alt-title {
    color: #FFF;
    font-size: 15px;
    font-weight: bold;
  }

  &__subtitle {
    color: @lighter-gray;
    font-size: 1.3rem;
  }

  &__views {
    margin-left: 1rem;

    &:before {
      content: @fa-var-eye;
      font-family: "Font Awesome 5 Pro";
      margin-right: .5rem;
    }
  }

  &__player {
    position: relative;
    overflow: hidden;
  }

  &__cta {
    margin: 2rem !important;
  }

  &__infos {
    background: @dark-gray;
    padding: 2rem;

    &--elite {
      display: flex;
    }
  }

  &__cover {
    flex: 0 0 23rem;
    padding-right: 2rem;

    img {
      display: block;
      width: 100%;
    }
  }

  &__description {
    color: @white;
    font-size: 1.3rem;
    border-bottom: 1px solid @gray;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  &__actions {
    &__row {
      display: flex;
      width: 100%;
      margin-bottom: .5rem;
    }
  }

  &__action {
    background: #313030;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    color: #fff;
    border: none;
    outline: none;
    text-decoration: none;
    padding: 1.5rem 2rem;
    width: 100%;
    font-size: 1.5rem;
    cursor: pointer;
    position: relative;
    text-align: center;

    &:hover {
      background-color: @light-gray;
    }

    &--green {
      background: @green;
      color: white;

      &:hover {
        background-color: @dark-green;
      }
    }

    &__icon {
      pointer-events: none;

      &--red {
        color: var(--main-color);
      }
    }

    &--split {
      display: flex;

      .video-detail__action__text,
      .video-detail__action__icon {
        width: 100%;
      }

      .video-detail__action__icon {
        color: #747474;
        text-decoration: none;
        cursor: pointer;
        padding: 0;
        outline: none;
        border-right: 1px solid #747474;
        margin-right: 1rem;

      }
    }

    &-item {
      position: relative;
      flex: 1;
      padding: 0 .2rem;
    }

    &__alert {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @green;
      border-radius: 1rem;
      color: white;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity ease 250ms;
    }

    &--invalid {
      .video-detail__action__alert {
        opacity: 1;
        visibility: visible;
      }
    }
  }


  .jm-url, .jm-phone {
    color: var(--main-color);
  }

  .margin-player {
    &.straceo {
      .cta--straceo {
        display: block !important;
      }

      .mediawrappers {
        filter: blur(10px);
      }
    }

    .jw-tab-focus:focus {
      outline: none !important;
    }

    p {
      color: var(--main-color);
      font-size: 1.8rem;
      text-indent: 1rem;
    }
  }

  .contact-link {
    color: @white;
    font-size: 1.3rem;
    border-bottom: 1px solid @gray;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    p {
      margin: 0;
    }

    a {
      color: @green;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__tags {
    padding-right: 2rem;
  }

  &__tag-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-bottom: .25rem;

    &__title {
      font-size: 1.3rem;
      color: @lighter-gray;
      margin-right: .5rem;
    }

    &__item {
      font-size: 1.3rem;
      color: @lighter-gray;
      margin-bottom: .25rem;
    }

    &__link {
      font-size: 1.3rem;
      color: var(--main-color);
    }
  }

  &__ctas {
    width: 100%;
    background: @dark-gray;
    padding: 1.5rem;
    display: flex;
    justify-content: center;

    .cta {
      margin: 0 .5rem;
    }

    &--fixed {
      position: fixed;
      left: 0;
      z-index: 3;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8), 0 15px 30px rgba(0, 0, 0, 0.5);
    }

    &--bottom {
      bottom: 0;
    }
  }
}

.sidebar-content {
  padding-left: 1rem;
  padding-right: 0;

  .unavoidable-videos {
    margin-top: 0;
    padding-top: 1rem;
  }
}

.button-container {
  position: relative;

  a {
    padding: 1.5rem 0;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    color: @white;
    display: block;
    font-size: 1.8rem;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    white-space: normal;
    text-align: center;
    background-color: @green;
    text-transform: uppercase;
    .transition(all ease 500ms);

    &:hover, &:focus, &:active {
      background-color: @dark-green;
      color: @white;
    }
  }

  .flash-container {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    overflow: hidden;
    border-radius: 5px;
    pointer-events: none;
    user-select: none;
  }

  .flash {
    position: absolute;
    left: -30%;
    width: 0;
    height: 100%;
    .transform(rotate(15deg));
    .box-shadow(0 0 50px 15px rgba(255, 255, 255, .75));
  }

  .animating {
    .animation(flash-animate 3000ms ease-in-out infinite);
  }
}

.video-pub {
  margin-top: 20px;

  img {
    max-width: 100%;
  }
}


.dropdown-playlist {
  background: @gray;
  border-radius: .5rem;
  color: white;
  padding: 0;
  width: 100%;
  overflow: hidden;

  &__title {
    margin: 0;
    padding: 1rem;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid @light-gray;
  }

  &__add {
    width: 100%;
    background: var(--main-color);
    border: none;
    color: white;
    padding: 1rem;
  }

  &__form {
    display: none;

    &--show {
      display: flex;
    }

  }

  &__input {
    border: none;
    padding: 1rem;
    flex: 1;
  }

  &__submit {
    padding: 1rem;
    color: white;
    background: var(--main-color);
    font-weight: bold;
  }

  &__list {
    width: 100%;
    padding: 1rem;
    display: none;
    flex-direction: column;

    &--show {
      display: flex;
    }

    a {
      color: var(--main-color);
    }

    i {
      margin-right: 1rem;
    }
  }
}

/* BANNERS */

.free-banner {
  border: 2px solid #4bd5ea;
  background: #4bd5ea57;
  text-align: center;
  padding: 3rem;
  color: #ffffff;
  margin-bottom: 2rem;
  font-weight: 700;

  &__label {
    color: black;
    background: #4ad4e9;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: .25rem 1rem;
    font-weight: bold;
    font-size: 1.3rem;
  }

  &__underline {
    text-decoration: underline;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
  }
}

.confirmation-buy {
  margin-top: 2rem;
  border: 1px solid @green;
  text-align: center;
  color: @green;
  padding: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;

  a {
    color: @green;
    text-decoration: underline;
    font-weight: bold;
  }

  .facebook {
    color: @lighter-gray;
    display: inline-block;
    margin: 0;
    margin-right: 2rem;
  }
}


@media screen and (max-width: 768px) {
  .video-detail {
    &__cta {
      margin: 1rem 0 !important;
    }

    &__cover {
      flex: 1;
      padding: 0;
      margin-bottom: 2rem;
    }

    &__infos {
      &--elite {
        flex-wrap: wrap;
      }
    }

    &__links {
      flex-wrap: wrap;
      align-items: center;
    }

    &__tags {
      padding: 0;
    }

    &__actions {
      width: 100%;
      margin-top: 1rem;
    }

    &__action {
      text-align: center;
    }
  }
}
