.advanced-search {
  margin-bottom: 2rem;
  border: 1px solid @dark-gray;

  &__title {
    background: @dark-gray;
    padding: 1rem 2rem;
    color: white;
    display: flex;
    align-items: center;

    &__icon {
      color: var(--main-color);
      margin-right: 1rem;
    }
  }

  &__list {
    max-height: 50rem;
    overflow: auto;
    padding: 1rem 0;

    &::-webkit-scrollbar {
      width: 15px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #565656; /* color of the scroll thumb */
      border: 4px solid black;
      border-radius: 50px; /* roundness of the scroll thumb */
    }
  }

  &__tag {
    display: block;
    width: 100%;
    padding: .5rem 2rem;
    line-height: 1;
    color: #686868;

    &--active {
      color: var(--main-color);
      display: flex;
      align-items: center;
    }

    &__icon {
      margin-right: 1rem;
    }
  }
}
