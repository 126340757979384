:root {
  --main-color: #3c7bff;
  --dark-main-color: darken(#3c7bff, 10%);
  --container-width: 1440px;
}

html {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 62.5%;
  height: 100%;
  color: @black;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem; /* =15px */
  background-color: @black;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;

  &.has-cta-bottom {
    footer {
      padding-bottom: 7rem;
    }
  }
}

.force-blur {
  filter: blur(20px) !important;
}
