.alert {
  color: white;
  padding: 1rem;
  text-align: center;
  border-radius: .5rem;

  &-info {
    color: @blue;
    border: 1px solid @blue;
  }

  &-success {
    color: @green;
    border: 1px solid @green;
  }

  &-warning {

  }

  &-error, &-danger {
    color: @red;
    border: 1px solid @red;
  }
}
